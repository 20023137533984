import React from 'react'
import TimeToHire from '../components/recruitment_blogs/blogs/time_to_hire/time_to_hire'
import SEO from '../components/seo'

const Page = (location) => {
  return (
    <React.Fragment>
        <SEO title="6 Ways to Improve Time to Hire in 2021"
          description="Here are top 6 tips to improve time to hire. How to measure Time to Hire? This article gives you all the info you need. Learn more."
          url="/time-to-hire/"
          image="https://blogs.mycareerdreams.com/blogs/ready_to_hire_banner.webp"
          type="article"
        />
     <TimeToHire location={location}/>
    </React.Fragment>
  )
}
export default Page;